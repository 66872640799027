<template>
  <v-bottom-sheet
        v-model="dialog"
        max-width="100vw"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            outlined
            elevation="2"
            x-large
            class="px-2"
            id="l-btn"
            width="20%"
            v-bind="attrs"
            v-on="on"
          >
            Locations
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">
            Select Locations
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="model"
              :items="items"
              dense
              filled
              auto-select-first
            chips
            clearable
            deletable-chips
            multiple
            ></v-autocomplete>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="deep-purple"
              x-large
              elevation="2"
              @click="submitLocations"
              class="px-10"
            >
              Good!
            </v-btn>
          </v-card-actions>
        </v-card>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'LocationSelector',
  data: () => ({
    activator: null,
    attach: null,
    dialog: false,
    nonce: 1,
    menu: false,
    model: [],
    x: 0,
    search: null,
    y: 0
  }),
  computed: {
    items () {
      const temp = []
      this.$store.state.locations_list.forEach(ele => {
        temp.push({
          text: ele,
          value: ele
        })
      })
      return temp
    }
  },
  methods: {
    submitLocations () {
      this.$store.commit('setPreferredLocations', this.model)
      this.dialog = false
    }
  }
}
</script>

<style scoped>
#l-btn {
  background-color: rgba(255,255,255,0.3) !important;
}
</style>
