<template>

  <div class="home">
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm12 md8>
          <v-card outlined color="rgba(0, 0, 0, 0)">
            <v-card-title>
              <v-img
              contain
              lazy-src="/engine_logo_sm.png"
              max-height="70vh"
              src="/engine_logo.png"
            ></v-img>
            </v-card-title>
            <v-card-actions
            class="d-flex justify-space-around" xs12 md6>
              <v-btn
              light
              elevation="2"
              x-large
              @click="goToPlan"
              v-touch:tap="goToPlan"
              class="px-2"
              width="40%"
              >
                Plan Your Trip
              </v-btn>
              <v-btn
              color="deep-purple"
              dark
              elevation="2"
              x-large
              @click="letsGo"
              v-touch:tap="letsGo"
              class="px-2"
              width="40%"
              >
                Let's Go!
              </v-btn>
            </v-card-actions>
            <v-card-text class="d-flex justify-center align-center">
              <a href="#" class="linkfix" @click="showHelp"><h4>what is this?</h4></a>
            </v-card-text>
            <v-card-text v-show="showEmojiCode">
              <v-form>
                <div>
                    <v-text-field
                    outlined
                    v-model="inEmoji"
                    label="Paste Your Playlist EmojiCode"
                    hint="Or leave blank for a new random experience."
                    :rules="[emojiMask]"
                    persistent-hint
                    class='mb-2'
                    @focus="$event.target.select()"
                  ></v-text-field>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
          <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            class="snackbarfix"
          >
            {{ snackbar_text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-flex>
      </v-layout>
    </v-container>
        <v-bottom-navigation
      class="elevation-0 btmnav"
    >
    <v-flex align-center justify-center>
      <v-row align-center justify-center>
        <v-col class="text-center py-0">
        <span class="smol" ><a href="https://themadonesmusical.com/" target="_blank">THE MAD ONES Engine was created by Liz Fisher, Kait Kerrigan, Tom Kinstrey, Patrick W. Lord, Brian Lowdermilk, and the members of THE MAD ONES Lab</a></span>
        </v-col>
      </v-row>
      <v-row align-center justify-center>
        <v-col class="text-center py-0">
        <span class="smol xsmol"><a href="https://libretto.tech" target="_blank">Web App by Tom Kinstrey</a></span>
        </v-col>
      </v-row>
    </v-flex>
    </v-bottom-navigation>
  </div>
</template>

<script>
// @ is an alias to /src
// import Player from '@/components/Player.vue'

// const emojiRegex = require('emoji-regex/RGI_Emoji.js')
// const { indexOf } = require('stringz')
// const bigInt = require('big-integer')

export function ytMaskRegex (value) {
  // const hours = [
  //   /[0-2]/,
  //   value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/
  // ]
  // const minutes = [/[0-5]/, /[0-9]/]
  // return value.length > 2 // if
  //   ? [...hours, ':', ...minutes] // then
  //   : hours // else

  const pat = /[0-9 A-Za-z_-]/
  const ytValid = new Array(1000).fill(pat)
  return ytValid
}

export default {
  name: 'Home',
  data () {
    return {
      inText: '',
      inEmoji: '',
      textMask: ytMaskRegex,
      timeout: 5000,
      snackbar: false,
      snackbar_text: 'Copied to clipboard!',
      emojiMask: value => {
        const pattern = /[0-9 A-Za-z_-]/
        return !pattern.test(value) || 'Invalid EmojiCode'
      },
      showEmojiCode: false
    }
  },
  components: {
  },
  mounted () {
    this.$store.dispatch('getVideoMatrix')
  },
  methods: {
    letsGo () {
      console.log(this.inText)
      this.$store.commit('createPlaylist', '')
      if (this.inText.length > 0) {
        this.$store.commit('loadPlaylist', this.inText)
      }
      if (this.inEmoji.length > 0) {
        this.$store.commit('loadEmojiPlaylist', this.inEmoji)
      }
      this.$store.dispatch('nextVideo').then(() => {
        this.$router.push({ name: 'Engine' })
      })
    },
    goToPlan () {
      this.$router.push({ name: 'Plan' })
    },
    showDevInfo () {
      this.snackbar_text = `Build: ${document.documentElement.dataset.buildTimestampUtc}`
      this.snackbar = true
      console.log(this.snackbar_text)
      this.timeout = 5000
    },
    showHelp () {
      this.snackbar_text = 'THE MAD ONES Engine is a web platform designed to create a seamless, randomized experience of the entire musical using all the short videos submitted by participants of THE MAD ONES Lab. Each time you start the Engine, a new version of the show plays. \n\nThe Engine creates a unique roadmap of your MAD ONES experience, allowing you to customize your viewing based on a specific criteria like artist names or geotags. It even saves your experience with what we’re calling an “emojimap” so you can easily share your favorite versions with friends.'
      this.snackbar = true
      this.timeout = 30000
    }
  },
  computed: {}
}
</script>

<style scoped>
div.v-snack:not(.v-snack--absolute) {
    height: 100%;
}
.linkfix {
  color: #fff !important;
}
.snackbarfix {
  white-space: pre-line !important;
}

.smol {
  color: #fff;
  font-size: 0.6em;
  text-decoration: none !important;
  margin-bottom: 0.5em;
}

.smol > a {
  color: #fff !important;
  text-decoration: none !important;
  margin-bottom: 0.5em
}
.btmnav {
  background-color: rgba(0,0,0,0) !important;
}
.xsmol {
  font-size: 0.4em;
}
</style>
