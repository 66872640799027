<template>
  <v-app>
    <v-main>
                <video-background
      src="/bg_loop.mp4"
      poster="/bg_img.jpg"
      style="height: 100vh;">
      <router-view/>
                </video-background>
    </v-main>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld'

export default {
  name: 'App',

  components: {
    // HelloWorld
  },

  data: () => ({
    //
  })
}
</script>

<style>
.video-wrapper, .video-buffering {
  position: fixed !important;
}
.vue-responsive-videobg {
  overflow: auto !important;
}

</style>
