import Vue from 'vue'
import VueRouter from 'vue-router'
// import Emoji from '../views/Emoji.vue'
import Engine from '../views/Engine.vue'
import Home from '../views/Home.vue'
import Plan from '../views/Plan.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/emoji',
    name: 'Emoji',
    component: () => import(/* webpackChunkName: "emoji" */ '../views/Emoji.vue'),
    meta: {
      title: 'EmojiCode'
    }
  },
  {
    path: '/v/:playlist_hash',
    name: 'Engine',
    component: Engine
  },
  {
    path: '/plan',
    name: 'Plan',
    component: Plan
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  }
]

const router = new VueRouter({
  routes
})

export default router
