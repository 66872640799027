<template>
  <div class="home">
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm12 md6>
          <v-card elevation="12" outlined shaped tile class="mx-auto">
            <v-row justify="center" class="yt-row">
              <youtube
                :video-id="videoId"
                :player-vars="player_vars"
                @ended="videoEnded"
                :id="videoId"
                ref="youtube"
                :fitParent="fitParent"
              >
              </youtube>
            </v-row>
            <v-card-title class="text-center justify-center">
              <v-overlay
              absolute
                opacity="0.8"
                :value="showHelp"
                >
                <v-btn
                  color="success"
                  @click="toggleHelp"
                >
                  Hide Overlay
                </v-btn>
              </v-overlay>
              <v-btn
                  color="primary"
                  elevation="2"
                  fab
                  small
                  @click="goPrev"
                  v-touch:tap="goPrev"
                  >
                    <v-icon color="white">mdi-arrow-left-bold</v-icon>
                  </v-btn>

                  <v-spacer></v-spacer>
              <div>
                <h1>{{$store.getters.getCurrentSectionTitle}}</h1>
              </div>
              <v-spacer></v-spacer>
                  <v-btn
                  color="primary"
                  elevation="2"
                  fab
                  small
                  @click="goNext"
                  v-touch:tap="goNext"
                  >
                    <v-icon color="white">mdi-arrow-right-bold</v-icon>
                  </v-btn>
            </v-card-title>
            <v-card-subtitle class="text-center">
              <v-row>
                <v-col>{{video.video_description}}</v-col>
              </v-row>
              <v-row v-show="videoId == 'Y_8LShrLPVw'">
                <v-col><a href="https://cyoa.libretto.tech" target="_blank" @click="player.pauseVideo()" style="color: #e91e63;">Visit the Choose Your Own Adventure Player for the full experience</a></v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-btn
                  color="primary"
                  elevation="2"
                  fab
                  x-small
                  outlined
                  @click="shuffle"
                  v-touch:tap="shuffle"
                  >
                  <v-icon color="white">mdi-shuffle</v-icon>
                  </v-btn>
                </v-col>
                <v-col class="py-0">
                  <v-btn
                  color="primary"
                  elevation="2"
                  fab
                  small
                  outlined
                  @click="openDetails"
                  v-touch:tap="openDetails"
                  >
                    <v-icon color="white">{{ details_arrow }}</v-icon>
                  </v-btn>
                </v-col>
                <v-col class="py-0">
                  <v-btn
                  color="primary"
                  elevation="2"
                  fab
                  x-small
                  outlined
                  @click="toggleHelp"
                  v-touch:tap="toggleHelp"
                  >
                  <v-icon color="white">{{ showHelp ? 'mdi-close' : 'mdi-help' }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-row class="yt-row">
              <div id="tmo-map">
                <l-map
                  ref="myMap"
                  :zoom="zoom"
                  :center="currentLatLng"
                  :options="mapOptions"
                  @ready="createMap()"
                  class="map-fix"
                >
                  <l-tile-layer
                    :url="url"
                    :attribution="attribution"
                  />
                  <l-marker :lat-lng="p.latLng" :key="p.index" v-for="p in pinHistory">
                    <l-popup>
                      <div>
                        {{p.name}}
                      </div>
                    </l-popup>
                  </l-marker>
                  <l-polyline
                    :lat-lngs="lineHistory"
                    color="purple"
                  />
                </l-map>
              </div>
            </v-row>
            <v-card-text class="d-flex justify-center">
              <transition name="slide-fade">
              <v-container class="credits" v-show="details">
                <v-row>
                  <v-col align="center">
                    <v-chip x-small class="ma-1" :color="t.color" outlined :key="t.index" v-for="t in tags">{{t.name}}</v-chip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="center">
                    <h2 class="py-2">{{video.team_name}}</h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">Team Leader:</v-col>
                  <v-col>{{video.tl_firstname}} {{video.tl_lastname}} ({{video.tl_pronouns}})</v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right"></v-col>
                  <v-col>{{video.tl_city}}</v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">Cast:</v-col>
                  <v-col>
                    <v-row :key="c.index" v-for="c in cast">
                      <v-col>{{ c.role }}</v-col>
                      <v-col>{{ c.name }}</v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">Section:</v-col>
                  <v-col>{{video.section_num}}</v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">Team Cities</v-col>
                  <v-col>{{video.team_city_list}}</v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">Number of Team Members</v-col>
                  <v-col>{{video.num_teammembers}}</v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">Surprising Fact:</v-col>
                  <v-col> {{ video.surprising_fact }}</v-col>
                </v-row>
              </v-container>
              </transition>
            </v-card-text>
            <v-card-actions class="d-flex justify-center align-center">

                  <v-spacer></v-spacer>
                  <div class="text-center">
                  <h6>Tap to copy EmojiMap playlist</h6>
                  <v-text-field
                  class="centered-input"
                  outlined
                  readonly
                  label=""
                  @click="copyEmojiCode"
                  v-touch:tap="copyEmojiCode"
                  :value="$store.getters.getPlaylistEmojiCode">
                  </v-text-field>
                  </div>
                  <v-spacer></v-spacer>
                  <!-- <v-breadcrumbs :items="$store.state.history">
                    <template v-slot:item="{ item }">
                      <v-breadcrumbs-item>
                      {{ item.name.toUpperCase() }}
                      </v-breadcrumbs-item>
                    </template>
                  </v-breadcrumbs> -->
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
          >
            {{ snackbar_text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
// import Player from '@/components/Player.vue'

import { latLng, Icon } from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup, LPolyline } from 'vue2-leaflet'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
  name: 'Engine',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LPolyline
  },
  data () {
    return {
      player_vars: {
        autoplay: 1
      },
      details: false,
      details_arrow: 'mdi-arrow-down-bold',
      showHelp: false,
      fitParent: true,
      snackbar: false,
      snackbar_text: '',
      timeout: 1500,
      map: null,
      zoom: 4,
      center: latLng(42.7297628, -73.6810824),
      mapOptions: {
        zoomSnap: 0.5
      },
      url: 'https://tiles.libretto.tech/styles/tmo/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withPopup: latLng(42.7297628, -73.6810824)
    }
  },
  methods: {
    videoEnded: function (event) {
      console.log('ENDED!')
      // console.log(event)
      this.player.pauseVideo()
      this.$store.dispatch('nextVideo').then(() => {
        this.player.playVideo()
      })
    },
    goNext: function (event) {
      this.player.pauseVideo()
      this.$store.commit('setPlayerState', this.$store.state.current_section + 1)
    },
    goPrev: function (event) {
      if (this.$store.state.current_section < 1) {
        this.$router.push({ name: 'Home' })
      }
      this.player.pauseVideo()
      this.$store.commit('setPlayerState', this.$store.state.current_section - 1)
    },
    copyEmojiCode () {
      navigator.clipboard.writeText(this.$store.getters.getPlaylistEmojiCode)
      console.log('Emoji Copied')
      this.snackbar_text = 'Emoji Copied to Clipboard!'
      this.snackbar = true
    },
    createMap () {
      console.log('CREATED THE MAP OBJECT')
      this.map = this.$refs.myMap.mapObject
    },
    openDetails () {
      this.details = !this.details
      this.details_arrow = this.details ? 'mdi-arrow-up-bold' : 'mdi-arrow-down-bold'
    },
    shuffle () {
      this.player.pauseVideo()
      this.$store.commit('setShuffle', '')
    },
    toggleHelp () {
      this.showHelp = !this.showHelp
    }
  },
  computed: {
    player () {
      return this.$refs.youtube.player
    },
    videoId () {
      const rawYt = this.$store.state.current_video.yt_link_full
      // eslint-disable-next-line no-useless-escape
      const ytRegex = /.*?(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/

      const theMatch = rawYt.match(ytRegex)
      // console.log(theMatch)

      return theMatch[1]
    },
    video () {
      return this.$store.state.current_video
    },
    cast () {
      const roles = this.$store.state.current_video.team_roles_list.split(' * ')
      const output = []

      let tomisbadatprogramming = 0
      roles.forEach(element => {
        const x = element.split('- ')
        output.push({
          role: x[0].trim(),
          name: x[1].trim(),
          index: tomisbadatprogramming
        })
        tomisbadatprogramming++
      })
      return output
    },
    tags () {
      // const pretags = this.$store.state.current_video.pretags_list.split(', ')
      // const customtags = this.$store.state.current_video.tags_list.split(',')
      const tags = this.$store.state.current_video.tags

      const output = []

      let tomisbadatprogramming = 0
      tags.forEach(ele => {
        let color = 'primary'
        if (this.$store.state.preferred_tags.indexOf(ele) > -1) {
          color = 'success'
        }
        output.push({
          name: ele,
          color: color,
          index: tomisbadatprogramming
        })
        tomisbadatprogramming++
      })
      // pretags.forEach(ele => {
      //   let color = 'primary'
      //   if (this.$store.state.preferred_tags.indexOf(ele.toUpperCase()) > -1) {
      //     color = 'success'
      //   }
      //   output.push({
      //     name: ele,
      //     color: color
      //   })
      // })

      // customtags.forEach(ele => {
      //   let color = 'primary'
      //   if (this.$store.state.preferred_tags.indexOf(ele.toUpperCase()) > -1) {
      //     color = 'success'
      //   }
      //   output.push({
      //     name: ele.trim(),
      //     color: color
      //   })
      // })

      return output
    },
    currentLatLng () {
      const googleUrl = this.$store.state.current_video.tl_google_map
      // eslint-disable-next-line no-useless-escape
      const regex = /http.*\/@(?<lat>-?\d*\.\d*),(?<lng>-?\d*\.\d*),(?<zzz>\d*z).*/

      const theMatch = googleUrl.match(regex)

      return latLng(theMatch.groups.lat, theMatch.groups.lng)
    },
    lineHistory () {
      const temp = []
      this.$store.state.history.forEach(ele => {
        const t = latLng(ele.lat, ele.lng)
        temp.push([t.lat, t.lng])
      })
      temp.push([this.currentLatLng.lat, this.currentLatLng.lng])
      return temp
    },
    pinHistory () {
      const temp = []
      this.$store.state.history.forEach(ele => {
        const t = latLng(ele.lat, ele.lng)
        temp.push({
          latLng: t,
          name: ele.name
        })
      })
      temp.push({
        latLng: this.currentLatLng,
        name: `Section ${this.$store.state.current_section}`
      })
      return temp
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.yt-row {
  margin-right: 0;
  margin-left: 0;
}

.credits .col{
  padding-bottom: 0;
  padding-top: 2px;
}

.map-fix {
  height: 100%;
  width: 100%;
  z-index: 0;
}

#tmo-map {
  height: 200px;
  width: 100%;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.centered-input >>> input {
  text-align: center
}
</style>
