<template>
  <v-bottom-sheet
        v-model="dialog"
        max-width="100vw"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            outlined
            elevation="2"
            x-large
            class="px-2"
            width="20%"
            id="e-btn"
            v-bind="attrs"
            v-on="on"
          >
            EmojiMap
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">
            EmojiMap Playlist
          </v-card-title>
          <v-card-text>
            <v-card-text>
                <v-form>
                  <div>
                      <v-text-field
                      outlined
                      v-model="inEmoji"
                      label="Paste Your EmojiMap Playlist"
                      hint="Or leave blank for a new random experience."
                      persistent-hint
                      class='mb-2'
                      @focus="$event.target.select()"
                    ></v-text-field>
                  </div>
                </v-form>
            </v-card-text>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="deep-purple"
              x-large
              elevation="2"
              @click="submitIt"
              class="px-10"
            >
              Good!
            </v-btn>
          </v-card-actions>
        </v-card>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'EmojiCodePopup',
  data: () => ({
    activator: null,
    attach: null,
    dialog: false,
    model: [],
    emojiMask: value => {
      const pattern = /[0-9 A-Za-z_-]/
      return !pattern.test(value) || 'Invalid EmojiMap'
    },
    inEmoji: ''
  }),
  computed: {
    items () {
      const temp = []
      return temp
    }
  },
  methods: {
    submitIt () {
      this.dialog = false
      if (this.inEmoji.length > 0) {
        this.$store.commit('loadEmojiPlaylist', this.inEmoji)
        this.$store.dispatch('nextVideo').then(() => {
          this.$router.push({ name: 'Engine' })
        })
      }
    }
  }
}
</script>

<style scoped>
#e-btn {
  background-color: rgba(255,255,255,0.3) !important;
}
</style>
