<template>

  <div class="home">
    <v-container fluid fill-height>
      <v-layout text-xs-center row wrap align-center style="height: 100vh;">
        <v-flex xs12>
            <v-card outlined color="rgba(0, 0, 0, 0.5)" img="/map_4_tile.svg" align-center justify-center class="ml-4">
              <!-- <v-img
                class="white--text align-end"
                height="100%"
                src="/map_4_tile.svg"
              > -->
              <v-card-title class="text-center justify-center">
                <div>
                  <h1>The Highway's Calling!</h1>
                  <h4>Select landmarks for your road trip</h4>
                </div>
              </v-card-title>
              <v-card-actions class="d-flex justify-space-around" xs12>
                <emoji-code-popup></emoji-code-popup>
              <tag-selector></tag-selector>
              <location-selector></location-selector>
              <people-selector></people-selector>
              </v-card-actions>
              <v-card-text>
                <v-spacer></v-spacer>
              </v-card-text>
              <v-card-text class="d-flex justify-center">
                <v-btn
              color="deep-purple"
              dark
              elevation="2"
              x-large
              @click="letsGoSpecial"
              v-touch:tap="letsGoSpecial"
              class="px-2"
              width="40%"
              >
                Let's Go!
              </v-btn>
              </v-card-text>
              <!-- </v-img> -->
            </v-card>
        </v-flex>
      </v-layout>
      <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
          >
            {{ snackbar_text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
// import TagSelector from '@/components/Tags.vue'

// const emojiRegex = require('emoji-regex/RGI_Emoji.js')
// const { indexOf } = require('stringz')
// const bigInt = require('big-integer')

export function ytMaskRegex (value) {
  const pat = /[0-9 A-Za-z_-]/
  const ytValid = new Array(1000).fill(pat)
  return ytValid
}

export default {
  name: 'Home',
  data () {
    return {
      inText: '',
      inEmoji: '',
      textMask: ytMaskRegex,
      timeout: 5000,
      snackbar: false,
      snackbar_text: 'Copied to clipboard!',
      emojiMask: value => {
        const pattern = /[0-9 A-Za-z_-]/
        return !pattern.test(value) || 'Invalid EmojiMap'
      },
      showEmojiCode: false
    }
  },
  components: {

  },
  mounted () {
    this.$store.dispatch('getVideoMatrix')
  },
  methods: {
    letsGo () {
      console.log(this.inText)
      if (this.inText.length > 0) {
        this.$store.commit('loadPlaylist', this.inText)
      }
      if (this.inEmoji.length > 0) {
        this.$store.commit('loadEmojiPlaylist', this.inEmoji)
      }
      this.$store.dispatch('nextVideo').then(() => {
        this.$router.push({ name: 'Engine' })
      })
    },
    letsGoSpecial () {
      this.$store.commit('createPlaylist', '')
      this.$store.dispatch('nextVideo').then(() => {
        this.$router.push({ name: 'Engine' })
      })
    },
    showDevInfo () {
      this.snackbar_text = `Build: ${document.documentElement.dataset.buildTimestampUtc}`
      this.snackbar = true
      console.log(this.snackbar_text)
    },
    toggleEmojiCode () {
      this.showEmojiCode = !this.showEmojiCode
      console.log('EmojiCode Toggled')
    }
  },
  computed: {}
}
</script>

<style scoped>
div.v-snack:not(.v-snack--absolute) {
    height: 100%;
}
.videobg-content {
  position: fixed !important;
}
</style>
